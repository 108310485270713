import React from "react";
import "./style.scss";
import { useTypewriter, Cursor } from "react-simple-typewriter";
import Me from "../../assets/images/me4.jpg";
import mystory from "../../assets/images/me6.png";
// import timeline from "../../assets/images/timeline.png";
// import timeline_light from "../../assets/images/timeline_light.png";
import skill from "../../assets/images/circle2.png";
import Button from "../../components/Main Button/Button";
import ContactMe from "../../containers/ContactMe/ContactMe";

const About = (props) => {
  // console.log(props.theme);
  const instagram = "https://www.instagram.com/fadmadrawings/";
  const mystory_post =
    "https://www.fadmadesigns.com/blog/how-i-got-into-ui-ux-as-a-software-engineer";
  const [role] = useTypewriter({
    words: ["UI/UX Designer", "Software Engineer"],
    loop: {},
  });
  return (
    <div className="container">
      <section className="hero_section">
        <div className="introduction">
          <h1>Hello, I’m Fadma</h1>
          <h2>
            I’m a <span className="my_role">{role}</span>
            <span className="cursor">
              <Cursor />
            </span>
          </h2>
        </div>
        <div className="details">
          <span className="sub_header">
            {/* I love turning imaginative ideas into interactive realities. */}
            I enjoy bringing creative ideas to life in a way that you can
            interact with.
          </span>
          <p>
            As a software engineer and designer, I thrive on the thrill of
            solving complex problems with elegant and simple solutions.
          </p>
        </div>
      </section>
      <section className="facts_aboutme">
        <div className="facts">
          <h1 className="section_title">Random facts about me</h1>
          <ul className="fact_list">
            <li>You'll usually find me with a coffee in hand ☕</li>
            <li>I'm a gym rat 🏋️‍♂️</li>
            <li>
              I love sketching people's faces, checkout my{" "}
              <a href={instagram} target="_blank" rel="noreferrer">
                drawings
              </a>
              🎨
            </li>
            <li>Planning is my superpower 🗓️</li>
            <li>Purple is my favorite color</li>
            <li>One of my goals is to visit India ✈️</li>
            <li>Minds and psychology, my obsession 🧠</li>
            <li>
              Before becoming a Software Engineer, I wanted to be a Psychiatrist
            </li>
            <li>Besides design, writing is my big passion ✍️</li>
            <li>I absolutely love working from home 👩‍💻</li>
          </ul>
        </div>
        <img src={Me} alt="" className="me_img" />
      </section>
      {/* <section className="timeline_section">
        <h1 className="section_title">Timeline</h1>
        <img src={props.theme === "dark" ? timeline : timeline_light} alt="" />
      </section> */}

      <section className="story_section">
        <div className="story_container">
          <h1 className="section_title">My story</h1>
          <div className="inside_container">
            <img src={mystory} alt="" className="me_img" />
            <div className="story_text">
              <h4>Interested in learning more about my journey?</h4>
              <p>
                Click here to get an inside look at my journey from wanting to
                become a psychologist to ending up in the tech world.
              </p>
              <Button
                text="Read my story"
                arrow={true}
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = mystory_post;
                }}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="skills_section">
        <h1 className="section_title">Skills</h1>
        <div className="skills">
          <div className="design_skills">
            <h4>UI/UX Design</h4>
            <ul>
              <li>User Research</li>
              <li>Empathy Mapping</li>
              <li>User Journey Maps</li>
              <li>User Personas</li>
              <li>Competitor Analysis</li>
              <li>Information Architecture</li>
              <li>Wireframing</li>
              <li>Prototyping</li>
              <li>Design Systems</li>
            </ul>
          </div>
          <img src={skill} alt="" />
          <div className="design_skills">
            <h4>Development</h4>
            <ul>
              <li>HTML, CSS, SCSS</li>
              <li>JavaScript</li>
              <li>Angular, TypeScript</li>
              <li>React</li>
              <li>Laravel</li>
              <li>Vue.js</li>
              <li>Node.js</li>
              <li>Responsive Web</li>
              <li>Flutter</li>
              <li>Java Android</li>
            </ul>
          </div>
        </div>
      </section>
      <section className="contact_section">
        <ContactMe />
      </section>
    </div>
  );
};

export default About;
