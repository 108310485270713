import React from "react";
import "./style.scss";

const Tab = ({ name, onClick, isActive }) => {
  return (
    <button className={`tab_btn ${isActive ? "active" : ""}`} onClick={onClick}>
      {name}
    </button>
  );
};

export default Tab;
