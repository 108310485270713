import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  QUERY_LAST2_POSTS_WHERE,
  QUERY_ONE_POST,
  graphcms,
} from "../../GraphQL/queries";
import AuthorBox from "../AuthorBox/AuthorBox";
import { format } from "date-fns";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import "./style.scss";
import SubscribeCard from "../NewsletterCard/SubscribeCard";
import BlogPost from "../../components/BlogPost/BlogPost";
import PostCardSkeleton from "../../components/Skeletons/PostCardSkeleton/PostCardSkeleton";

const BlogPostPage = ({ categories }) => {
  const { slug } = useParams([]);
  const navigate = useNavigate();
  // console.log(slug);
  const [post, setPost] = useState();
  // console.log(post);
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    graphcms
      .request(QUERY_LAST2_POSTS_WHERE, { slug })
      .then((res) => setPosts(res.blogPosts));
  }, [slug]);

  // console.log(posts);
  useEffect(() => {
    graphcms.request(QUERY_ONE_POST, { slug }).then((res) => {
      if (res.blogPost != null) {
        // console.log(res);
        var date = new Date(res.blogPost.datePublication);
        var formattedDate = format(date, "MMM do, yyyy");
        // console.log(formattedDate);
        res.blogPost.datePublication = formattedDate;
        setPost(res.blogPost);
      } else {
        navigate("/notfound", { replace: true });
      }
    });
  }, [slug, navigate]);

  return (
    <div className="container">
      <div className="post_container">
        <div className="post_content">
          <span className="post_date">
            {post?.datePublication || <Skeleton width="10%" />}
          </span>
          <h1 className="post_title">
            {post?.title || <Skeleton width="80%" />}
          </h1>
          {!post?.coverPhoto.url ? (
            <Skeleton height="400px" />
          ) : (
            <img className="post_cover" src={post?.coverPhoto.url} alt="" />
          )}
          <div>
            <h6 className="post_read_time">{post?.readTime} min read</h6>
            <span className="post_category">
              Category:{" "}
              <Link to={`/blog/category/${post?.categories[0].slug}`}>
                <span>
                  {post?.categories[0].name || <Skeleton width="10%" />}
                </span>
              </Link>
            </span>
          </div>

          <hr className="post_divider" />
          {!post?.content.html ? (
            <div>
              <Skeleton count={6} />
              <br />
              <Skeleton count={6} />
              <br />
              <Skeleton count={6} />
            </div>
          ) : (
            <div
              className="post_body"
              dangerouslySetInnerHTML={{
                __html: post?.content.html,
              }}
            ></div>
          )}
        </div>
        <div className="author_box">
          <AuthorBox />
          <div className="categs">
            <h4>Categories</h4>
            {categories?.map((cat) => (
              <Link to={`/blog/category/${cat.slug}`} key={cat.id}>
                <p key={cat.id}>{cat.name}</p>
              </Link>
            ))}
          </div>
        </div>
      </div>
      <SubscribeCard />
      <section className="blog_section">
        {posts.length === 0 ? (
          <br />
        ) : (
          <h2 className="section_title">Read next</h2>
        )}

        <div className="latest_articles">
          {posts.length === 0 ? (
            // <PostCardSkeleton num={2} />
            <br />
          ) : (
            posts?.map((post) => (
              <Link to={`/blog/${post.slug}`} key={post.id}>
                <BlogPost
                  key={post.id}
                  img={post.coverPhoto.url}
                  title={post.title}
                  date={post.datePublication}
                  minutes={post.readTime}
                />
              </Link>
            ))
          )}
        </div>
      </section>
    </div>
  );
};

export default BlogPostPage;
