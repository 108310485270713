import React from "react";
// import ServiceImg from "../../assets/images/service1.png";
import "./style.scss";
import { Link } from "react-router-dom";

const Service = (props) => {
  let class_name = "card__img1";
  switch (true) {
    case props.serviceName === "UI/UX Design":
      class_name = "card__img1";
      break;
    case props.serviceName === "Frontend Dev":
      class_name = "card__img2";
      break;
    case props.serviceName === "Mobile App Dev":
      class_name = "card__img3";
      break;
    default:
      class_name = "card__img1";
  }
  return (
    <div className="grid__item">
      <div className={class_name}></div>
      <div className="card__content">
        <h1 className="card__header">{props.serviceName}</h1>
        <p className="card__text">{props.description}</p>
        <Link to={`/portfolio/${props.slug}`}>
          <span className="card__btn">
            <span className="card__btn_text">View work</span>{" "}
            <span className="card__btn_arrow">&rarr;</span>
          </span>
        </Link>
      </div>
    </div>
  );
};

export default Service;
