import { Link, useParams } from "react-router-dom";
import React, { useState } from "react";
import Gradient from "../../assets/images/Gradient2.png";
import "./style.scss";
import Quote from "../../components/Quote/Quote";
import Principle1 from "../../assets/images/simple.png";
import Principle2 from "../../assets/images/human_centric.png";
import Principle3 from "../../assets/images/wabi_sabi.png";
import ContactMe from "../../containers/ContactMe/ContactMe";
import Project from "../../components/Project/Project";
import ScrollToTop from "react-scroll-to-top";
import TabSkeleton from "../../components/Skeletons/TabSkeleton/TabSkeleton";
import Tab from "../../components/Tab/Tab";

const Portfolio = ({ projects }) => {
  const { slug } = useParams([]);
  const [activeTab, setActiveTab] = useState(!slug ? "all" : slug);

  const projectsReversed = [...projects].reverse();
  // console.log(projectsReversed);
  const principle1 = {
    name: "Simple is more",
    quote: {
      content: "Design is so simple, that’s why it’s so complicated",
      author: "-Paul Rand",
    },
    desc: "I believe in stripping away the unnecessary to create designs that effortlessly guide users, ensuring clarity and ease of use.",
  };
  const principle2 = {
    name: "Human-Centric",
    quote: {
      content:
        "We should design for the way people behave, not for how we would wish them to behave.",
      author: "-Don Norman",
    },
    desc: "I place a deep understanding of human behavior and cognition at the core of my work. By integrating psychology and neuroscience, I craft experiences that resonate with humans on a personal level.",
  };
  const principle3 = {
    name: "Wabi-Sabi",
    quote: {
      content:
        "Good design is making something intelligible and memorable. Great design is making something memorable and meaningful.",
      author: "-Dieter Rams",
    },
    desc: "I design with 'Wabi-Sabi Inspiration,' celebrating imperfection, finding charm in the irregular and unconventional, crafting unique and memorable designs.",
  };
  const tabs = [
    { name: "UI/UX Design", slug: "ui-ux" },
    { name: "Web Development", slug: "web-dev" },
    { name: "Mobile Development", slug: "mobile-dev" },
  ];
  const handleTabClick = (categ) => {
    // console.log(`${categ} clicked`);
    setActiveTab(categ);
  };
  return (
    <div className="container">
      <img src={Gradient} alt="" className="portf_gradient" />
      <section className="portfolio_header">
        <div className="quote">
          <h1 className="quote1">Designing the Future,</h1>
          <h1 className="quote2">One Project at a Time.</h1>
        </div>
        <div className="header_text">
          <h4>
            I specialize in bringing your vision to life through seamless design
            and coding.
          </h4>
          <p>
            My passion lies in translating your ideas into practical, visually
            appealing designs. With a knack for coding, I bring these designs to
            reality, delivering a seamless and engaging user experience.
          </p>
        </div>
      </section>
      <section className="dp_section">
        <h2 className="section_title">My design principles</h2>
        <div className="principles">
          <div className="principle">
            <img src={Principle1} alt="" />
            <h4>{principle1.name}</h4>
            <Quote
              quote={principle1.quote.content}
              author={principle1.quote.author}
            />
            <p>{principle1.desc}</p>
          </div>
          <div className="principle">
            <img src={Principle2} alt="" />
            <h4>{principle2.name}</h4>
            <Quote
              quote={principle2.quote.content}
              author={principle2.quote.author}
            />
            <p>{principle2.desc}</p>
          </div>
          <div className="principle">
            <img src={Principle3} alt="" />
            <h4>{principle3.name}</h4>
            <Quote
              quote={principle3.quote.content}
              author={principle3.quote.author}
            />
            <p>{principle3.desc}</p>
          </div>
        </div>
      </section>
      <section className="projects_section">
        <h2 className="section_title">My work</h2>
        <div className="tabs">
          <Tab
            name="All"
            onClick={() => handleTabClick("all")}
            isActive={activeTab === "all" ? 1 : 0}
          />
          {tabs.empty ? (
            <TabSkeleton num={4} />
          ) : (
            tabs.map((tab) => (
              <Tab
                key={tab.slug}
                name={tab.name}
                onClick={() => handleTabClick(tab.slug)}
                isActive={activeTab === tab.slug ? 1 : 0}
              />
            ))
          )}
        </div>
        {/* <div className="tabs">
          <div className="tab active">
            <span>All</span>
          </div>
          {tabs.map((tab) => (
            <div className="tab">
              <span>{tab}</span>
            </div>
          ))}
        </div> */}
        <div className="projects">
          {activeTab !== "all"
            ? projectsReversed.map((project) =>
                project.category === activeTab ? (
                  <Link to={`/portfolio/${project.slug}`} key={project.id}>
                    <Project
                      key={project.id}
                      img={project.img}
                      category={project.tag}
                      name={project.name}
                      action={project.action}
                    />
                  </Link>
                ) : (
                  ""
                )
              )
            : projectsReversed.map((project) => (
                <Link to={`/portfolio/${project.slug}`} key={project.id}>
                  <Project
                    key={project.id}
                    img={project.img}
                    category={project.tag}
                    name={project.name}
                    action={project.action}
                  />
                </Link>
              ))}
        </div>
      </section>
      <section className="contact_section">
        <ContactMe />
      </section>
      <ScrollToTop smooth color="#fff" className="back_to_top" />
    </div>
  );
};

export default Portfolio;
