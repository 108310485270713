import React from "react";
import Skeleton from "react-loading-skeleton";
import "./style.scss";

const PostCardSkeleton = ({ num }) => {
  return Array(num)
    .fill(0)
    .map((_, i) => (
      <div className="post_ske_container" key={i}>
        <Skeleton height={250} />
        <br />
        <Skeleton height={20} />
        <br />
        <Skeleton width="50%" />
      </div>
    ));
};

export default PostCardSkeleton;
