import React, { useEffect } from "react";
import "./style.scss";

const SubstackWidget = () => {
  useEffect(() => {
    // Define the CustomSubstackWidget on the window object
    window.CustomSubstackWidget = {
      substackUrl: "fadma.substack.com",
      placeholder: "example@gmail.com",
      buttonText: "Subscribe",
      theme: "custom",
      colors: {
        primary: "#c26d37",
        input: "#323232",
        email: "#FFFFFF",
        text: "#ffffff",
      },
    };

    // Create a new script element
    const script = document.createElement("script");

    // Set the source of the script to the Substack widget script
    script.src = "https://substackapi.com/widget.js";
    script.async = true;

    // Append the script to the body
    document.body.appendChild(script);

    // Cleanup function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return <div id="custom-substack-embed" className="signup_form"></div>;
};

export default SubstackWidget;
