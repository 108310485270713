import { GraphQLClient, gql } from "graphql-request";

export const graphcms = new GraphQLClient(process.env.REACT_APP_API);

export const QUERY_CATEGORIES = gql`
{
    categories(){
        name,
        id,
        slug
    }
}
`;

export const QUERY_BLOG_POSTS = gql`
  {
    blogPosts(orderBy: updatedAt_DESC) {
      id
      slug
      title
      coverPhoto {
        url
      }
      datePublication
      content {
        html
      }
      categories {
        name
        slug
      }
      readTime
    }
  }
`;

export const QUERY_LAST2_POSTS = gql`
  {
    blogPosts(orderBy: updatedAt_DESC, first: 2) {
      id
      slug
      title
      coverPhoto {
        url
      }
      datePublication
      content {
        html
      }
      categories {
        name
        slug
      }
      readTime
    }
  }
`;

export const QUERY_LAST2_POSTS_WHERE = gql`
  query GetLatestPosts($slug: String!) {
    blogPosts(last: 2, where: { NOT: { slug: $slug } }) {
      id
      slug
      title
      coverPhoto {
        url
      }
      datePublication
      content {
        html
      }
      categories {
        name
        slug
      }
      readTime
    }
  }
`;
export const QUERY_ONE_POST = gql`
  query GetOnePost($slug: String!) {
    blogPost(where: { slug: $slug }) {
      categories {
        name
        slug
      }
      content {
        html
      }
      coverPhoto {
        url
      }
      datePublication
      id
      readTime
      title
      slug
    }
  }
`;
