import React, { useContext } from "react";
import { ReactComponent as Sun } from "./Sun.svg";
import { ReactComponent as Moon } from "./Moon.svg";
import "./darkmode.scss";
import { ThemeContext } from "../../Context/theme.ts";

const DarkMode = () => {
  const { theme, setTheme } = useContext(ThemeContext);

  const handleThemeChange = (e) => {
    const isCurrentDark = theme === "dark";
    setTheme(isCurrentDark ? "light" : "dark");
  };

  // const setDark = () => {
  //   localStorage.setItem("theme", "dark");
  //   // document.documentElement.setAttribute("", "dark");
  // };

  // const setLight = () => {
  //   localStorage.setItem("theme", "light");
  //   // document.documentElement.setAttribute("data-theme", "light");
  // };

  // const storedTheme = localStorage.getItem("theme");

  // const prefersDark =
  //   window.matchMedia &&
  //   window.matchMedia("(prefers-color-scheme: dark)").matches;

  // const defaultDark =
  //   storedTheme === "dark" || (storedTheme === null && prefersDark);

  // if (defaultDark) {
  //   setDark();
  // }

  // const toggleTheme = (e) => {
  //   if (e.target.checked) {
  //     setDark();
  //   } else {
  //     setLight();
  //   }
  // };
  return (
    <div className="dark_mode">
      <input
        className="dark_mode_input"
        type="checkbox"
        id="darkmode-toggle"
        onChange={handleThemeChange}
        checked={theme === "dark"}
      />
      <label className="dark_mode_label" htmlFor="darkmode-toggle">
        <Sun />
        <Moon />
      </label>
    </div>
  );
};

export default DarkMode;
