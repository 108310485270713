import { React, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "./style.scss";
import Gradient from "../../assets/images/Gradient3.png";
import BlogPost from "../../components/BlogPost/BlogPost";
import Tab from "../../components/Tab/Tab";
// import { useNavigate } from "react-router-dom";
import TabSkeleton from "../../components/Skeletons/TabSkeleton/TabSkeleton";
import PostCardSkeleton from "../../components/Skeletons/PostCardSkeleton/PostCardSkeleton";
import ScrollToTop from "react-scroll-to-top";
// import { Resend } from "resend";
import axios from "axios";
import { Resend } from "resend";
import SubstackWidget from "../../components/Substack/SubstackForm";

const Blog = ({ posts, categories }) => {
  const [showResults, setShowResults] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [errors, setErrors] = useState({});

  const resend = new Resend(process.env.REACT_APP_RESEND_API);
  const { slug } = useParams([]);
  // const navigate = useNavigate();
  // console.log(slug);
  const [formData, setFormData] = useState({
    email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    // Real-time validation
    const validationErrors = validateField(name, value);
    setErrors({
      ...errors,
      [name]: validationErrors,
    });
    // console.log(errors);
  };

  const validateField = (name, value) => {
    // console.log(formData);
    // Basic validation rules (you can modify these rules)
    const errors = [];
    switch (name) {
      case "email":
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (value.trim() === "") {
          errors.push("Please enter your email");
        } else if (!emailRegex.test(value)) {
          errors.push("Invalid email address");
        }
        break;
      // Add validation rules for other fields as needed
      default:
        break;
    }
    return errors;
  };
  // useEffect(() => {
  //   if (slug) {
  //     const found = posts.some((post) => post.categories[0].slug === slug);
  //     if (!found) {
  //       navigate("/notfound", { replace: true });
  //     }
  //   }
  // }, [slug, navigate, posts]);
  const [activeTab, setActiveTab] = useState(!slug ? "all" : slug);

  const handleTabClick = (categ) => {
    // console.log(`${categ} clicked`);
    setActiveTab(categ);
  };

  // try {
  //   const response = await axios.post(
  //     "http://localhost:3000/emails",
  //     {
  //       from: "onboarding@resend.dev",
  //       to: email,
  //       subject: "Welcome to the club!",
  //       react: <Welcome />,
  //     },
  //     {
  //       headers: {
  //         Authorization: `Bearer ${process.env.REACT_APP_RESEND_API}`,
  //         "Access-Control-Allow-Origin": true,
  //       },
  //     }
  //   );
  //   console.log("Email sent", response.data);
  // } catch (error) {
  //   console.error("Error creating post:", error);
  //   setShowLoader(false);
  // }

  // console.log(api);
  // const resend = new Resend(api);
  const subscribeClick = async (e) => {
    e.preventDefault();
    setShowLoader(true);
    console.log(formData);
    // Validate all fields before submission
    const validationErrors = {};
    Object.keys(formData).forEach((name) => {
      const value = formData[name];
      const fieldErrors = validateField(name, value);
      if (fieldErrors.length > 0) {
        validationErrors[name] = fieldErrors;
      }
    });

    // If there are errors, do not submit the form
    if (Object.keys(validationErrors).length > 0) {
      console.log(errors);
      setErrors(validationErrors);
      setShowLoader(false);
      return;
    }
    try {
      const response = await axios.post(
        "http://localhost:3000/audiences/cae49bed-db5c-47dc-8b80-25cab140acee/contacts",
        {
          email: formData.email,
          first_name: "",
          last_name: "",
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_RESEND_API}`,
            "Access-Control-Allow-Origin": true,
          },
        }
      );
      console.log("User subscribed:", response.data);
      setShowLoader(false);
      setFormData({ email: "" });
      setShowResults(true);
      // SEND THE WELCOME EMAIL
    } catch (error) {
      console.error("Error creating post:", error);
      setShowLoader(false);
    }
  };

  // try {
  //   const response = await axios.post(
  //     "https://api.resend.com/emails",
  //     {
  //       from: "Acme <onboarding@resend.dev>",
  //       to: email,
  //       subject: "Welcome to the club!",
  //       React: Welcome(),
  //     },
  //     {
  //       headers: {
  //         Authorization: `Bearer ${process.env.REACT_APP_RESEND_API}`,
  //         "Access-Control-Allow-Origin": true,
  //       },
  //     }
  //   );
  //   console.log("User subscribed:", response.data);
  //   setShowLoader(false);
  //   setFormData({ email: "" });
  //   setShowResults(true);
  //   // SEND THE WELCOME EMAIL
  //   sendEmail(formData.email);
  // } catch (error) {
  //   console.error("Error creating post:", error);
  //   setShowLoader(false);
  // }

  return (
    <div className="container">
      <img src={Gradient} alt="" className="blog_gradient" />
      <section className="blog_header">
        <h1>My Blog</h1>
        <h4>
          Hi there! In this blog, I write about UI/UX design and the fascinating
          psychology that shapes it. You'll find also insights, lessons, and
          handy tips I've gathered along the way. If you're curious, take a
          stroll through my thoughts.
        </h4>
        <p>
          Join my newsletter where I document my journey, sharing my personal
          insights, actionable tips, and all things UI/UX and more. Let's keep
          the conversation going beyond the pixels!
        </p>
        <SubstackWidget />
        {/* <form className="subs_form" onSubmit={subscribeClick}>
          <input
            name="email"
            type="email"
            placeholder="Your email"
            value={formData.email}
            onChange={handleChange}
          />

          <Button text="subscribe" loading={showLoader} disabled={showLoader} />
        </form>
        {errors.email && <div className="error">{errors.email.join(", ")}</div>}
        {showResults ? <Results /> : null} */}
      </section>
      <section className="blog_section">
        <h2 className="section_title">Blog</h2>
        <div className="tabs">
          <Tab
            name="All"
            onClick={() => handleTabClick("all")}
            isActive={activeTab === "all" ? 1 : 0}
          />
          {!categories[0] ? (
            <TabSkeleton num={4} />
          ) : (
            categories.map((tab) => (
              <Tab
                name={tab.name}
                key={tab.id}
                onClick={() => handleTabClick(tab.slug)}
                isActive={activeTab === tab.slug ? 1 : 0}
              />
            ))
          )}
        </div>
        <div className="blog_posts">
          {posts.length === 0 ? (
            <PostCardSkeleton num={4} />
          ) : activeTab !== "all" ? (
            posts?.map((post) =>
              post.categories[0].slug === activeTab ? (
                <Link to={`/blog/${post.slug}`} key={post.id}>
                  <BlogPost
                    key={post.id}
                    img={post.coverPhoto.url}
                    title={post.title}
                    date={post.datePublication}
                    minutes={post.readTime}
                  />
                </Link>
              ) : (
                ""
              )
            )
          ) : (
            posts?.map((post) => (
              <Link to={`/blog/${post.slug}`} key={post.id}>
                <BlogPost
                  key={post.id}
                  img={post.coverPhoto.url}
                  title={post.title}
                  date={post.datePublication}
                  minutes={post.readTime}
                />
              </Link>
            ))
          )}
        </div>
      </section>
      <ScrollToTop smooth color="#fff" className="back_to_top" />
    </div>
  );
};

const Results = () => (
  <p id="results" className="subscribe_thank">
    Thank you for subscribing! I'm glad to have you on board. Please check your
    inbox.
  </p>
);

export default Blog;
