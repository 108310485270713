import React from "react";
import Gradient from "../../assets/images/Gradient.png";
import "./style.scss";
import SubscribeCard from "../../containers/NewsletterCard/SubscribeCard";

const Resources = () => {
  const instagram = "https://www.instagram.com/fadmadesigns/";
  return (
    <div className="container">
      <img src={Gradient} alt="" className="gradient" />
      <section className="resources_header">
        <h1>Coming Soon</h1>
        <p>
          Follow me on{" "}
          <a href={instagram} target="_blank" rel="noreferrer">
            Instagram
          </a>{" "}
          for sneak peeks, behind-the-scenes, and real-time updates.
        </p>
      </section>
      <section className="newsletter_section">
        <SubscribeCard />
      </section>
    </div>
  );
};

export default Resources;
