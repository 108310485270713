import React from "react";
import profilePic from "../../assets/images/profile2.png";
import {
  FaInstagram,
  FaLinkedin,
  FaBehance,
  FaGithub,
  FaDribbble,
} from "react-icons/fa";
import "./style.scss";

const AuthorBox = () => {
  const linkedin = "https://www.linkedin.com/in/fadmaennaji/";
  const instagram = "https://www.instagram.com/fadmadesigns/";
  const behance = "";
  const github = "https://github.com/fadmaennaji/";
  const dribble = "";
  return (
    <div className="box_container">
      <img src={profilePic} alt="" />
      <h6>Hi, I’m Fadma Ennaji</h6>
      <p>
        I'm a Software Engineer and a UI/UX Designer, a multipassionate
        individual who enjoys writing about a range of interests, from design
        and code to personal development and psychology.
      </p>
      <div className="box_socials">
        <a href={linkedin} target="_blank" rel="noreferrer">
          <FaLinkedin className="box_social_icon" />
        </a>
        <a href={instagram} target="_blank" rel="noreferrer">
          <FaInstagram className="box_social_icon" />
        </a>
        <a href={behance} target="_blank" rel="noreferrer">
          <FaBehance className="box_social_icon" />
        </a>
        <a href={dribble} target="_blank" rel="noreferrer">
          <FaDribbble className="box_social_icon" />
        </a>
        <a href={github} target="_blank" rel="noreferrer">
          <FaGithub className="box_social_icon" />
        </a>
      </div>
    </div>
  );
};

export default AuthorBox;
