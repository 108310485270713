import React from "react";
import "./style.scss";

const Quote = (props) => {
  return (
    <div className="quote_container">
      <div className="quote_line"></div>
      <div className="quote_content">
        <p>{props.quote}</p>
        <span>{props.author}</span>
      </div>
    </div>
  );
};

export default Quote;
