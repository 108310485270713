import React from "react";
import "./style.scss";
import { useNavigate } from "react-router-dom";

import myPhoto from "../../assets/images/profile2.png";
import Button from "../../components/Main Button/Button";

const ContactMe = () => {
  let navigate = useNavigate();
  return (
    <div className="contact_card">
      <img className="contact_img" src={myPhoto} alt="" />
      <h2>Want to work with me?</h2>
      <p>
        <span>If you are looking for a freelance designer or developer.</span>
        <span>
          Reach out to me, let's discuss your exciting project further.
        </span>
      </p>
      <Button
        text="Let's work tegether"
        onClick={() => {
          navigate("/contact");
        }}
        arrow={true}
      />
    </div>
  );
};

export default ContactMe;
