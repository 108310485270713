import React from "react";
import Skeleton from "react-loading-skeleton";

const TabSkeleton = ({ num }) => {
  return Array(num)
    .fill(0)
    .map((_, i) => <Skeleton key={i} width={160} height={40} />);
};

export default TabSkeleton;
