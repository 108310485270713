import React from "react";
import MessageSent from "../../assets/images/sent_message.png";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Main Button/Button";
import "./style.scss";

const ThankYouPage = () => {
  const navigate = useNavigate();
  const navigateTo = () => navigate("/");
  const myEmail = "contact@fadmadesigns.com";
  return (
    <div className="container">
      <div className="thankYou_container">
        <div className="thankYou_message">
          <h1>Thank You for Your Submission!</h1>
          <h4>We appreciate your interest in working with me.</h4>
          <p>
            Your form has been successfully submitted, and we'll get back to you
            as soon as possible.
          </p>
          <p>
            If you have any urgent inquiries or need immediate assistance, feel
            free to contact me directly at{" "}
            <a href={`mailto:${myEmail}`}>{myEmail}</a>.
          </p>
          <Button text="Go back home" onClick={navigateTo} />
        </div>
        <img src={MessageSent} alt="" />
      </div>
    </div>
  );
};

export default ThankYouPage;
