import React, { useState } from "react";
import "./style.scss";
import LoadingPlaceHolder, {
  containerStyles,
} from "../LoadingPlaceHolder/LoadingPlaceHolder";

const Project = (props) => {
  const [loaded, setLoaded] = useState(false);
  // let imageLoadNum = 0;

  const stuffLoaded = () => {
    // imageLoadNum++;
    // if (imageLoadNum < 2) return;
    // ------- replace setTimer with https request from server
    setTimeout(() => {
      setLoaded(true);
    }, 2000);
  };
  return (
    <div className="proj__card">
      <div style={containerStyles}>
        {!loaded && (
          <LoadingPlaceHolder
            extraStyles={{
              height: "100%",
              top: "0",
              left: "0",
              borderRadius: "18px",
            }}
            container
          />
        )}
        <img
          className="proj__img"
          src={props.img}
          alt="project"
          onLoad={stuffLoaded}
        />
      </div>

      {!loaded && (
        <LoadingPlaceHolder
          extraStyles={{
            height: "30px",
            width: "30%",
            // marginBottom: "16px",
            borderRadius: "10px",
          }}
        />
      )}
      {loaded && (
        <div className="proj__category">
          <p>{props.category}</p>
        </div>
      )}
      {!loaded && (
        <LoadingPlaceHolder
          extraStyles={{
            height: "30px",
            width: "90%",
            // marginBottom: "16px",
            borderRadius: "10px",
          }}
        />
      )}
      {loaded && <h1 className="proj__title">{props.name}</h1>}

      {!loaded && (
        <LoadingPlaceHolder
          extraStyles={{
            height: "30px",
            width: "80%",
            // marginBottom: "16px",
            borderRadius: "10px",
          }}
        />
      )}
      {loaded &&
        (props.action != "Coming Soon" ? (
          <span className="card__btn">
            <span className="card__btn_text">{props.action}</span>{" "}
            <span className="card__btn_arrow">&rarr;</span>
          </span>
        ) : (
          <p className="coming_soon">{props.action}</p>
        ))}
    </div>
  );
};

export default Project;
