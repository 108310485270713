/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { Link } from "react-router-dom";
import "./style.scss";
// import { ReactComponent as Coffee } from "../../assets/icons/coffee1.svg";
import me from "../../assets/images/header_img.svg";
import {
  FaInstagram,
  FaLinkedin,
  FaBehance,
  FaGithub,
  FaDribbble,
} from "react-icons/fa";

const BioSection = () => {
  const linkedin = "https://www.linkedin.com/in/fadmaennaji/";
  const instagram = "https://www.instagram.com/fadmadesigns/";
  const behance = "";
  const github = "https://github.com/fadmaennaji/";
  const dribble = "";

  return (
    <div className="bio-section">
      <div className="bio_img">
        <div className="bio">
          <h3>Hi, I'm</h3>
          <h1>Fadma Ennaji</h1>
          <div className="role">
            <span className="span">&lt;</span>
            <span className="role_name">Software Engineer</span>
            <span className="span">/&gt;</span>
            <span className="role_name"> &amp; Product Designer</span>
          </div>
          {/* <p>
            Crafting seamless user experiences and bringing designs to life with
            code while enjoying coffee <Coffee />.
          </p> */}
          <p>
            Designing seamless user experiences & developing solutions that make
            an impact.
          </p>
          <div className="call_to_action">
            <p>
              <Link to="/portfolio">
                <span className="hyperlink">View my projects</span>
              </Link>{" "}
              or{" "}
              <Link to="/about">
                <span className="hyperlink">Read about me</span>
              </Link>
            </p>
          </div>
        </div>

        <img className="profile_img" loading="lazy" src={me} alt="profile" />
      </div>

      <div className="social-media">
        <div className="FindMeHere">Find me here</div>
        <a href={linkedin} target="_blank" rel="noreferrer">
          <FaLinkedin className="social_icon" />
        </a>
        <a href={instagram} target="_blank">
          <FaInstagram className="social_icon" />
        </a>
        <a href={behance} target="_blank">
          <FaBehance className="social_icon" />
        </a>
        <a href={dribble} target="_blank">
          <FaDribbble className="social_icon" />
        </a>
        <a href={github} target="_blank">
          <FaGithub className="social_icon" />
        </a>
      </div>
    </div>
  );
};

export default BioSection;
