/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { Link } from "react-router-dom";
import "./footer.scss";
import {
  FaInstagram,
  FaLinkedin,
  FaBehance,
  FaGithub,
  FaDribbble,
} from "react-icons/fa";
import Logo from "../../assets/images/white_logo.png";

const Footer = () => {
  const linkedin = "https://www.linkedin.com/in/fadmaennaji/";
  const instagram = "https://www.instagram.com/fadmadesigns/";
  const behance = "";
  const github = "https://github.com/fadmaennaji/";
  const dribble = "";

  return (
    <div className="footer_container">
      <div className="sitemap">
        <Link to="/">
          <img className="footer_logo" src={Logo} alt="" />
        </Link>
        <div className="navigation_links">
          <div className="navigation">
            <h5>Hello</h5>
            <Link to="/about">
              <p>About</p>
            </Link>
            <Link to="/blog">
              <p>Blog</p>
            </Link>
            <Link to="/contact">
              <p>Contact me</p>
            </Link>
            <Link to="/blog">
              <p>Newsletter</p>
            </Link>
            <Link to="/resources">
              <p>Resources</p>
            </Link>
          </div>
          <div className="navigation">
            <h5>Services</h5>
            <p>UI/UX Design</p>
            <p>Frontend Development</p>
            <p>Mobile App Development</p>
          </div>
          <div className="navigation">
            <h5>Latest Projects</h5>
            <p>Immo-Macht Dashboard Redesign</p>
            <p>FiTrakr</p>
            <p>My website</p>
          </div>
        </div>
      </div>
      <hr className="footer-divider" />
      <div className="contact_info">
        <p className="copyright">© 2023 Fadma Ennaji</p>
        <div className="social_media">
          <a href={linkedin} target="_blank" rel="noreferrer">
            <FaLinkedin className="social_icon_small" />
          </a>
          <a href={instagram} target="_blank">
            <FaInstagram className="social_icon_small" />
          </a>
          <a href={behance} target="_blank">
            <FaBehance className="social_icon_small" />
          </a>
          <a href={dribble} target="_blank">
            <FaDribbble className="social_icon_small" />
          </a>
          <a href={github} target="_blank">
            <FaGithub className="social_icon_small" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
