import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import logo from "../../assets/images/purple_logo.png";
import "./style.scss";
import DarkMode from "../../components/Dak Mode/DarkMode";
import Button from "../../components/Main Button/Button";
import { BiMenuAltRight } from "react-icons/bi";

function Header() {
  const [menuOpened, setMenuOpened] = useState(false);
  const openMenu = () => setMenuOpened(!menuOpened);
  let navigate = useNavigate();
  const closeMenu = () => {
    setMenuOpened(false);
  };
  // const routeChange = () => {
  //   let path = `/contact`;
  //   navigate(path);
  // };
  return (
    <header>
      <NavLink exact="true" to="/">
        <img className="purple_logo" src={logo} alt="Your Logo" />
      </NavLink>

      <nav className={menuOpened ? "nav_menu active" : "nav_menu"}>
        <NavLink
          className={({ isActive }) => (isActive ? "navLinkactive" : "navLink")}
          to="/about"
          onClick={closeMenu}
        >
          About
        </NavLink>
        <NavLink
          className={({ isActive }) => (isActive ? "navLinkactive" : "navLink")}
          to="/blog"
          onClick={closeMenu}
        >
          Blog
        </NavLink>
        <NavLink
          className={({ isActive }) => (isActive ? "navLinkactive" : "navLink")}
          to="/portfolio"
          onClick={closeMenu}
        >
          Portfolio
        </NavLink>
        {/* <div className="navLink">Portfolio</div> */}
        <NavLink
          className={({ isActive }) => (isActive ? "navLinkactive" : "navLink")}
          to="/resources"
          onClick={closeMenu}
        >
          Resources
        </NavLink>
        <div onClick={closeMenu}>
          <DarkMode />
        </div>
        <Button
          text="Work with me"
          onClick={() => {
            navigate("/contact");
            closeMenu();
          }}
        />
      </nav>

      <div className="menu_icon" onClick={openMenu}>
        <BiMenuAltRight size={51} />
      </div>
    </header>
  );
}

export default Header;
