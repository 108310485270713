import React, { useState } from "react";
import myPhoto from "../../assets/images/profile2.png";
import Button from "../../components/Main Button/Button";
import "./style.scss";
import axios from "axios";
import SubstackWidget from "../../components/Substack/SubstackForm";

const SubscribeCard = () => {
  // const [showResults, setShowResults] = useState(false);
  // const [showLoader, setShowLoader] = useState(false);

  return (
    <div className="contact_card">
      <img className="contact_img" src={myPhoto} alt="" />
      <h2>Subscribe to my newsletter</h2>
      <p>
        <span>
          Join my newsletter where I document my journey, sharing my personal
          insights, actionable tips, and all things UI/UX and more.
        </span>
        {/* <span>Let's keep the conversation going beyond the pixels!</span> */}
      </p>
      <SubstackWidget />
      {/* <form className="subscribe_form" onSubmit={subscribe}>
        <input
          name="email"
          type="email"
          placeholder="Your email"
          value={formData1.email}
          onChange={handleChange}
        />

        <Button text="subscribe" loading={showLoader} disabled={showLoader} />

        
      </form>
      {errors.email && <div className="error">{errors.email.join(", ")}</div>}
      {showResults ? <Results /> : null} */}
    </div>
  );
};

const Results = () => (
  <p id="results" className="subscribe_thank">
    Thank you for subscribing! I'm glad to have you on board. Please check your
    inbox.
  </p>
);
export default SubscribeCard;
