import React from "react";
import { ReactComponent as Loader } from "../../assets/icons/loader.svg";
import "./style.scss";

const Button = ({ text, onClick, arrow, loading, disabled }) => {
  return (
    <div>
      <button className="main_btn" onClick={onClick} disabled={disabled}>
        {!loading ? (
          <span>
            {text} {arrow && <span className="arrow_icon">&rarr;</span>}
          </span>
        ) : (
          <Loader className="spinner" />
        )}
      </button>
    </div>
  );
};

export default Button;
