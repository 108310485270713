import React, { useState } from "react";
import Select from "react-select";
import Gradient from "../../assets/images/Gradient.png";
import Button from "../../components/Main Button/Button";
import emailjs from "@emailjs/browser";
import { useNavigate } from "react-router-dom";

import "./style.scss";

const Contact = ({ theme }) => {
  const [showLoader, setShowLoader] = useState(false);
  const navigate = useNavigate();

  const servicesOptions = [
    { value: "design", label: "UI/UX Design" },
    { value: "frontDev", label: "Frontend Development" },
    { value: "mobileDev", label: "Mobile Development" },
    { value: "other", label: "Other" },
  ];
  const socialOptions = [
    { value: "linkedin", label: "Linkedin" },
    { value: "instagram", label: "Instagram" },
    { value: "behance", label: "Behance" },
    { value: "online", label: "Online Search" },
    { value: "ref", label: "Referral" },
    { value: "other", label: "Other" },
  ];

  const [formData, setFormData] = useState({
    fullname: "",
    email: "",
    businessName: "",
    businessDescription: "",
    serviceWanted: null,
    serviceDetails: "",
    foundMeBy: null,
    foundMeSource: "",
    budget: "",
    message: "",
  });

  const [errors, setErrors] = useState({});

  const handleSelectChange = (selectedOption, { name }) => {
    // console.log(selectedOption);
    setFormData({
      ...formData,
      [name]: selectedOption,
    });

    // Real-time validation
    const validationErrors = validateField(name, selectedOption);
    setErrors({
      ...errors,
      [name]: validationErrors,
    });
  };

  const handleChange = (e) => {
    // console.log(e.target);
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // Real-time validation
    const validationErrors = validateField(name, value);
    setErrors({
      ...errors,
      [name]: validationErrors,
    });
    // console.log(errors);
  };

  const validateField = (name, value) => {
    // console.log(formData);
    // Basic validation rules (you can modify these rules)
    const errors = [];
    switch (name) {
      case "fullname":
        const nameRegex = /^[a-zA-Z\s]+$/;
        if (value.trim() === "" || !nameRegex.test(value)) {
          errors.push(
            "Please enter a valid full name with only letters and spaces."
          );
        }
        break;
      case "email":
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (value.trim() === "" || !emailRegex.test(value)) {
          errors.push("Invalid email address");
        }
        break;
      case "businessName":
        if (value.trim() === "") {
          errors.push("Business Name is required.");
        }
        break;
      case "serviceWanted":
        if (!value || !value.value) {
          errors.push("Please select a service");
        }
        break;
      case "serviceDetails":
        if (
          formData.serviceWanted === null ||
          formData.serviceWanted.value === "other"
        ) {
          errors.push("Please specify the service you're looking for");
        }
        break;
      case "foundMeBy":
        if (!value || !value.value) {
          errors.push("Please select how you found me.");
        }
        break;
      case "foundMeSource":
        if (
          formData.foundMeBy === null ||
          formData.foundMeBy.value === "other"
        ) {
          errors.push("Please provide information on how you found me :).");
        }
        break;
      // Add validation rules for other fields as needed
      default:
        break;
    }
    return errors;
  };
  // const handleServiceChange = (service) => {
  //   console.log(service);
  //   setService(service.value);
  // };

  // const handleFoundChange = (way) => {
  //   console.log(way);
  //   setFoundMe(way.value);
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowLoader(true);
    // Validate all fields before submission
    const validationErrors = {};
    Object.keys(formData).forEach((name) => {
      const value = formData[name];
      const fieldErrors = validateField(name, value);
      if (fieldErrors.length > 0) {
        validationErrors[name] = fieldErrors;
      }
    });

    // If there are errors, do not submit the form
    if (Object.keys(validationErrors).length > 0) {
      // console.log(errors);
      setErrors(validationErrors);
      setShowLoader(false);
      return;
    }
    // console.log(formData);

    // If all fields are valid, you can proceed with form submission logic here
    // console.log("Form submitted:", formData);
    emailjs
      .send(
        "service_1bkt09d",
        "template_ng48j6h",
        formData,
        "ih7a5YELEh20AJ2vD"
      )
      .then(
        (result) => {
          // console.log(result.text);
          setShowLoader(false);
          navigate("/success");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  // const submitForm = (event) => {
  //   event.preventDefault();
  //   const data = new FormData(event.target);
  //   console.log(Object.fromEntries(data.entries()));
  //   // const formData = {
  //   //   fullname: fullname,
  //   //   email: email,
  //   //   businessName: busName,
  //   //   businessBrief: busBrief,
  //   //   budget: budget,
  //   //   service: selectedService,
  //   //   serviceDetail: serviceDetails,
  //   //   foundMeIn: selectedWay,
  //   //   foundMeBy: foundBy,
  //   //   message: message,
  //   // };
  //   // console.log(formData);
  // };

  const CustomSelectStyle = {
    container: (baseStyles, state) => ({
      ...baseStyles,
      width: "100%",
    }),
    singleValue: (baseStyles, state) => ({
      ...baseStyles,
      color: theme === "dark" ? "white" : "#323232",
    }),
    menu: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: theme === "dark" ? "#5b5959" : "#edece8",
    }),
    control: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: theme === "dark" ? "#5b5959" : "#edece8",
      color: "white",
      width: "100%",
      height: "56px",
      paddingLeft: "15px",
      boxShadow: state.isFocused
        ? "#c26d37"
        : theme === "light"
        ? "0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.3)"
        : "none",
      ":hover": { borderColor: "#c26d37" },
      ":focus": { borderColor: "#c26d37" },
      borderRadius: "8px",
      borderColor: state.isFocused
        ? "#c26d37"
        : theme === "dark"
        ? "#323232"
        : "",
    }),
    option: (styles, state) => ({
      ...styles,
      ":hover": { backgroundColor: "#c26d37" },
      color: theme === "dark" ? "white" : "#323232",
      backgroundColor: state.isSelected ? "#c26d37" : "",
    }),
  };

  const emailAddress = "contact@fadmadesigns.com";

  return (
    <div className="container">
      <img src={Gradient} alt="" className="gradient" />
      <section className="contact_header">
        <h1>Let’s get in touch!</h1>
        <p>
          If you're seeking a freelance designer and developer to bring your
          project to life, feel free to reach out via{" "}
          <a href={`mailto:${emailAddress}`} target="_blank" rel="noreferrer">
            email
          </a>{" "}
          or complete the form below, and let's collaborate to turn your ideas
          into remarkable digital experiences.
          <p>I'm excited to explore the possibilities together.</p>
        </p>
      </section>
      <form className="form_section" onSubmit={handleSubmit}>
        {/* full name & company name */}
        <div className="two_fields">
          <div className="field">
            <p>Your full name *</p>
            <input
              id="fullname"
              name="fullname"
              value={formData.fullname}
              type="text"
              placeholder="Please provide your full name"
              onChange={handleChange}
              // onChange={(e) => setFullname(e.target.value)}
            />
            {errors.fullname && (
              <div className="error">{errors.fullname.join(", ")}</div>
            )}
          </div>
          <div className="field">
            <p>Your email *</p>
            <input
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              type="email"
              placeholder="Please provide your email"
              // onChange={(e) => setEmail(e.target.value)}
            />
            {errors.email && (
              <div className="error">{errors.email.join(", ")}</div>
            )}
          </div>
        </div>
        <div className="field">
          <p>Your business name *</p>
          <input
            id="businessName"
            name="businessName"
            value={formData.businessName}
            onChange={handleChange}
            type="text"
            placeholder="Please provide you company/business name"
            // onChange={(e) => setBusName(e.target.value)}
          />
          {errors.businessName && (
            <div className="error">{errors.businessName.join(", ")}</div>
          )}
        </div>
        <div className="field">
          <p>What does your business do?</p>
          <textarea
            name="businessDescription"
            rows="10"
            placeholder="Briefly describe your business"
            onChange={handleChange}
          ></textarea>
        </div>

        <div className="select_field">
          <p>Service *</p>
          <Select
            isSearchable={false}
            id="serviceWanted"
            name="serviceWanted"
            onChange={(selectedOption) =>
              handleSelectChange(selectedOption, { name: "serviceWanted" })
            }
            value={formData.serviceWanted}
            options={servicesOptions}
            // onChange={handleServiceChange}
            placeholder={"I need help in"}
            styles={CustomSelectStyle}
          />
          {errors.serviceWanted && (
            <div className="error">{errors.serviceWanted.join(", ")}</div>
          )}
        </div>
        {formData.serviceWanted?.value === "other" ? (
          <div className="field">
            <p>Service details *</p>
            <input
              id="serviceDetails"
              name="serviceDetails"
              value={formData.serviceDetails}
              onChange={handleChange}
              type="text"
              placeholder="Please specify the service you're looking for..."
              // onChange={(e) => setServDetail(e.target.value)}
            />
            {errors.serviceDetails && (
              <div className="error">{errors.serviceDetails.join(", ")}</div>
            )}
          </div>
        ) : (
          ""
        )}
        <div className="field">
          <p>Your budget</p>
          <input
            id="budget"
            name="budget"
            value={formData.budget}
            type="number"
            placeholder="$"
            onChange={handleChange}
          />
        </div>
        <div className="select_field">
          <p>How did you hear about me? *</p>
          <Select
            isSearchable={false}
            name="foundMeBy"
            id="foundMeBy"
            options={socialOptions}
            onChange={(selectedOption) =>
              handleSelectChange(selectedOption, { name: "foundMeBy" })
            }
            placeholder={"I heard about your services..."}
            styles={CustomSelectStyle}
          />
          {errors.foundMeBy && (
            <div className="error">{errors.foundMeBy.join(", ")}</div>
          )}
        </div>
        {formData.foundMeBy?.value === "other" ? (
          <div className="field">
            <p>How did you find me? *</p>
            <input
              id="foundMeSource"
              name="foundMeSource"
              value={formData.foundMeSource}
              type="text"
              placeholder="..."
              onChange={handleChange}
            />
            {errors.foundMeSource && (
              <div className="error">{errors.foundMeSource.join(", ")}</div>
            )}
          </div>
        ) : (
          ""
        )}
        <div className="field">
          <p>Your message</p>
          <textarea
            name="message"
            rows="10"
            placeholder="More details about the project"
            onChange={handleChange}
          ></textarea>
        </div>
        <div className="send_btn">
          <Button
            text="Send message"
            arrow={true}
            loading={showLoader}
            disabled={showLoader}
          />
        </div>
      </form>
    </div>
  );
};

export default Contact;
