import { React, useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import Header from "../../containers/Header/Header";
import BioSection from "../../containers/BioSection/BioSection";
import "../../views/home/style.scss";
// import { QUERY_BLOG_POSTS, graphcms } from "../../GraphQL/queries.js";
import Service from "../../components/ServiceCard/Service";
import Project from "../../components/Project/Project";
import BlogPost from "../../components/BlogPost/BlogPost";
import ContactMe from "../../containers/ContactMe/ContactMe";
// import LoaderSpinner from "../../components/LoaderSpinner/LoaderSpinner";
import { QUERY_LAST2_POSTS, graphcms } from "../../GraphQL/queries";
import PostCardSkeleton from "../../components/Skeletons/PostCardSkeleton/PostCardSkeleton";
import ScrollToTop from "react-scroll-to-top";
// import Footer from "../../containers/Footer/Footer";

const Home = ({ projects }) => {
  const [posts, setPosts] = useState([]);
  const latestProjects = projects.slice(-2);
  // console.log(latestProjects);

  useEffect(() => {
    graphcms.request(QUERY_LAST2_POSTS).then((res) => setPosts(res.blogPosts));
  }, []);

  // console.log(posts);
  const service1 = {
    name: "UI/UX Design",
    desc: "I specialize in designing user-friendly interfaces that captivate your audience and enhance satisfaction",
  };

  const service2 = {
    name: "Frontend Dev",
    desc: "I bring designs to life with responsive websites. Focusing on performance and aesthetics, making your web presence stand out",
  };

  const service3 = {
    name: "Mobile App Dev",
    desc: "I build engaging, native, and cross-platform mobile apps that streamline processes and achieve your goals",
  };

  return (
    <div className="container">
      <BioSection />
      <section className="services_section">
        <h2 className="section_title">What I do</h2>
        <div className="services">
          <Service
            serviceName={service1.name}
            description={service1.desc}
            slug="ui-ux"
          />
          <Service
            serviceName={service2.name}
            description={service2.desc}
            slug="web-dev"
          />
          <Service
            serviceName={service3.name}
            description={service3.desc}
            slug="mobile-dev"
          />
        </div>
      </section>

      <section className="work_section">
        <h2 className="section_title">Latest work</h2>
        <div className="latest_work">
          {/* ------- FETCH RECENT PROJECTS------------------- */}

          {[...latestProjects].reverse()?.map((project) => (
            <Link to={`/${project.slug}`} key={project.id}>
              <Project
                key={project.id}
                img={project.img}
                category={project.tag}
                name={project.name}
                action={project.action}
              />
            </Link>
          ))}
          {/* <span className="btn_arrow">&rarr;</span> */}
        </div>

        <span className="home_hyperlink">
          <Link to="/portfolio">
            <span className="home_hyperlink_text">Checkout my portfolio</span>
          </Link>{" "}
          <span className="home_hyperlink_arrow">&rarr;</span>
        </span>
      </section>

      <section className="blog_section">
        <h2 className="section_title">Latest articles</h2>
        <div className="latest_articles">
          {posts.length === 0 ? (
            <PostCardSkeleton num={2} />
          ) : (
            posts?.map((post) => (
              <Link to={`/blog/${post.slug}`} key={post.id}>
                <BlogPost
                  key={post.id}
                  img={post.coverPhoto.url}
                  title={post.title}
                  date={post.datePublication}
                  minutes={post.readTime}
                />
              </Link>
            ))
          )}
          {/* <BlogPost
            img={blog1.img}
            title={blog1.title}
            date={blog1.date_posted}
            minutes={blog1.minutes}
          />
          <BlogPost
            img={blog2.img}
            title={blog2.title}
            date={blog2.date_posted}
            minutes={blog2.minutes}
          /> */}
        </div>
        <span className="home_hyperlink">
          <Link to="/blog">
            <span className="home_hyperlink_text">More on the blog</span>
          </Link>{" "}
          <span className="home_hyperlink_arrow">&rarr;</span>
        </span>
      </section>

      <section className="contact_section">
        <ContactMe />
      </section>

      {/* <div style={{ marginTop: "150vh" }} /> */}
      <ScrollToTop smooth color="#fff" className="back_to_top" />
    </div>
  );
};

export default Home;
