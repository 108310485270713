import React from "react";
import Button from "../../components/Main Button/Button";
import NotFound from "../../assets/images/404_error.png";
import { useNavigate } from "react-router-dom";
import "./style.scss";

const NotFoundPage = () => {
  const navigate = useNavigate();
  const navigateTo = () => navigate("/");
  return (
    <div className="container">
      <div className="error_container">
        <div className="error_message">
          <h1>Oops...</h1>
          <h4>Page not found</h4>
          <p>
            We're sorry, the page you requested could not be found. Please go
            back to the home page.
          </p>
          <Button text="Go back home" onClick={navigateTo} />
        </div>
        <img src={NotFound} alt="" />
      </div>
    </div>
  );
};

export default NotFoundPage;
