import React from "react";
// import PostImg from "../../assets/images/post1.jpg";
import "./style.scss";

const BlogPost = (props) => {
  return (
    <div className="blog_post__card">
      <img className="blog_post__img" src={props.img} alt="" />

      <h1 className="blog_post__title">{props.title}</h1>

      <p className="blog_post__date">
        {props.date} • {props.minutes} minutes read
      </p>
    </div>
  );
};

export default BlogPost;
