import { ThemeContext } from "./Context/theme.ts";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useState, React, useEffect } from "react";
import "./App.scss";
import Home from "./views/home/Home";
import Header from "./containers/Header/Header.js";
import Footer from "./containers/Footer/Footer.js";
import BlogPostPage from "./containers/BlogPostPage/BlogPostPage.js";
import About from "./views/About/About.js";
import Contact from "./views/Contact/Contact.js";
import Portfolio from "./views/Portfolio/portfolio.js";
import Blog from "./views/Blog/Blog.js";
import Resources from "./views/Resources/Resources.js";
import {
  QUERY_BLOG_POSTS,
  QUERY_CATEGORIES,
  graphcms,
} from "./GraphQL/queries.js";
import ScrollToTop from "./ScrollToTop.js";
import NotFoundPage from "./views/404/NotFoundPage.js";
import { SkeletonTheme } from "react-loading-skeleton";
import ThankYouPage from "./views/ThankYouPage/ThankYouPage.js";

// export const ThemeContext = createContext(null);

function App() {
  const projects = [
    {
      id: 1,
      img: require("./assets/images/project1.png"),
      tag: "Web Development",
      category: "web-dev",
      name: "My website",
      action: "Coming Soon",
      slug: "",
    },
    {
      id: 2,
      img: require("./assets/images/project3.png"),
      tag: "Personal Project",
      category: "ui-ux",
      name: "FiTrakr",
      action: "Coming Soon",
      slug: "",
    },
    {
      id: 3,
      img: require("./assets/images/project4.png"),
      tag: "Client Project",
      category: "ui-ux",
      name: "Immot-Macht dashboard redesign",
      action: "Coming Soon",
      slug: "",
    },
  ];
  const [posts, setPosts] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    graphcms.request(QUERY_BLOG_POSTS).then((res) => setPosts(res.blogPosts));
  }, []);

  useEffect(() => {
    graphcms
      .request(QUERY_CATEGORIES)
      .then((res) => setCategories(res.categories));
  }, []);
  // console.log(categories);

  // console.log(posts);
  // const storedTheme = localStorage.getItem("theme");
  const [theme, setTheme] = useState("dark");
  // console.log(theme);
  // const [{ themename }] = useContext(ThemeContext);
  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <div className="App" id={theme}>
        <SkeletonTheme
          baseColor={theme === "dark" ? "#5b5959" : "#dddcda"}
          highlightColor={theme === "dark" ? "#747272" : "#edece8"}
          borderRadius="1rem"
        >
          <Router>
            <ScrollToTop />
            <Header />
            <Routes>
              <Route path="*" element={<NotFoundPage />} />
              <Route path="/" element={<Home projects={projects} />}></Route>
              <Route path="/success" element={<ThankYouPage />}></Route>
              <Route path="/notfound" element={<NotFoundPage />} />
              <Route path="/blog/category" element={<NotFoundPage />} />
              <Route path="/about" element={<About theme={theme} />}></Route>
              <Route
                path="/contact"
                element={<Contact theme={theme} />}
              ></Route>
              <Route
                path="/portfolio"
                element={<Portfolio theme={theme} projects={projects} />}
              ></Route>
              <Route
                path="/blog"
                element={
                  <Blog theme={theme} posts={posts} categories={categories} />
                }
              ></Route>
              <Route
                path="/blog/category/:slug"
                element={
                  <Blog theme={theme} posts={posts} categories={categories} />
                }
              ></Route>
              <Route
                path="/portfolio/:slug"
                element={<Portfolio theme={theme} projects={projects} />}
              ></Route>
              <Route
                path="/blog/:slug"
                element={<BlogPostPage theme={theme} categories={categories} />}
              ></Route>
              <Route
                path="/resources"
                element={<Resources theme={theme} />}
              ></Route>
            </Routes>
            <Footer />
            {/* </ScrollToTop> */}
          </Router>
        </SkeletonTheme>
      </div>
    </ThemeContext.Provider>
  );
}

export default App;
